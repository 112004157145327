<template>
  <div class="aside" :class="menuOpen ? 'menu-close' : ''">
    <div class="aside-header">
      <img :src="'/media/logo-' + modeName + '.png'" alt="" class="logo" />
      <button class="btn btn-empty mr-auto minw-auto" @click="asideToggle">
        <i class="icon icon-menu-1 mr-10"></i>
      </button>
    </div>
    <ul>
      <template v-for="(item, i) in MainMenu" :key="i">
        <template v-for="(menuItem, j) in item.pages" :key="j">
          <template v-if="containsRole(menuItem.role)">
            <template v-if="menuItem.heading">
              <li @click="isActive = false">
                <router-link :to="menuItem.route" :class="menuItem.class">
                  <i :class="'icon icon-' + menuItem.icon + ' mr-10'" v-if="menuItem.icon"></i>
                  <span>{{ menuItem.heading }}</span>
                </router-link>
              </li>
            </template>
          </template>
        </template>
      </template>
    </ul>
    <div class="aside-footer">
      <div class="user">
        <div class="user-name">
          {{ this.$store.state.auth.user.given_name + " " + this.$store.state.auth.user.family_name }}
        </div>
        <div class="logout">
          <button class="btn btn-empty-danger" @click="logout">
            <i class="icon icon-logout mr-10"></i>
            Çıkış Yap
          </button>
        </div>
      </div>
      <div class="mode" v-if="false">
        <span class="title">Koyu Tema</span>
        <div class="form-check switch-checkbox">
          <input
            type="checkbox"
            id="mode-checkbox"
            class="form-check-input"
            v-model="mode"
            @change="modeSwtich"
          />
          <label class="form-check-label" for="mode-checkbox"></label>
        </div>
      </div>
      <p>Copyright {{ new Date().getFullYear() }}</p>
      <p>Tüm hakları saklıdır.</p>
    </div>
  </div>
</template>

<script>
import MainMenu from "../core/MainMenu.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      MainMenu: MainMenu,
      menuOpen: false,
      mode : false,
      modeName:"light",
    };
  },
  methods: {
    containsRole(pageRoleGroup) {
      const userRoleGroup = this.getUser.groups;
      const hasValidRole = pageRoleGroup.some((role) =>
        userRoleGroup.includes(role)
      );
      return hasValidRole;
    },
    asideToggle() {
      this.menuOpen = !this.menuOpen;
      this.$emit("aside-toggle", this.menuOpen);
    },
    modeSwtich() {
      if (this.mode) {
        document.querySelector("body").classList.add("light");
        document.querySelector("body").classList.remove("dark");
        this.setCookie("theme-mode", "light");
        this.$store.state.theme.theme_mode = "light";
        this.modeName = "light"
      } else {
        document.querySelector("body").classList.remove("light");
        document.querySelector("body").classList.add("dark");
        this.setCookie("theme-mode", "dark");
        this.$store.state.theme.theme_mode = "dark";
        this.modeName = "dark"
      }
    },
    logout() {
      window.location.reload();
    },
  },
  created() {
    let mode = this.getCookie("theme-mode");
    mode == "light" ? this.mode = true : this.mode = false
    this.modeName = mode ? mode : "light"
  },
  computed: {
    ...mapGetters("auth", {
      getUser: "getUser",
    }),
  },
};
</script>