const adminRoles = [
  "InternalUser",
  "ManagementUser",
  "ITUser",
  "AdminUser"
]
const customerRoles = [
  "CustomerUser"
]
const allRoles = [
  "InternalUser",
  "ManagementUser",
  "ITUser",
  "AdminUser",
  "CustomerUser"
]
const MainMenu = [
  {
    pages: [
      {
        heading: "Müşteriler",
        route: "/customers",
        icon: 'profile-2user',
        role: adminRoles
      },
      {
        heading: "Kullanıcılar",
        route: "/users",
        icon: 'people',
        role: adminRoles
      },
      {
        heading: "Geliştirici Hesapları",
        route: "/platform-accounts",
        icon: 'user-square',
        role: adminRoles
      },
      
      // {
      //   heading: "Mağazalar",
      //   route: "/stores",
      //   icon: 'store',
      //   role: roles
      // },
      {
        heading: "Mobil Uygulamalar",
        route: "/apps",
        icon: 'mobile',
        role: adminRoles
      },
      {
        heading: "Yeni Bildirim Oluştur",
        route: "/apps",
        icon: '',
        role: customerRoles,
        class:"notification"
      },
      {
        heading: "Üyelik Planları",
        route: "/subscriptions",
        icon: 'note-1',
        role: adminRoles
      },
      {
        heading: "Gönderilen Bildirimler",
        route: "/notifications",
        icon: 'notification',
        role: allRoles
      },
    ],
  },
];

export default MainMenu;
