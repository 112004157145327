<template>
  <Aside @aside-toggle="menuToggle" />
  <div class="wrapper" :class="isMenuClose ? 'menu-close' : ''">
    <div class="bread-creamps">
      <template v-if="breadcreamps.length == 1">
        <span>{{ breadcreamps[0].title }}</span>
      </template>
      <template v-else>
        <template v-for="(breadcreamp, index) in breadcreamps" :key="index">
          <template v-if="index == breadcreamps.length - 1">
            <span>
              {{ breadcreamp.title }}
            </span>
          </template>
          <template v-else-if="!breadcreamp.path">
            <span>
              {{ breadcreamp.title }}
            </span>
            <i class="icon icon-arrow-right-3"></i>
          </template>
          <template v-else>
            <router-link :to="breadcreamp.path">
              {{ breadcreamp.title }}
            </router-link>
            <i class="icon icon-arrow-right-3"></i>
          </template>
        </template>
      </template>
    </div>
    <router-view />
  </div>
</template>

<script>
import Aside from "./Aside.vue";
import { mapGetters } from "vuex";
export default {
  name: "Layout",
  components: {
    Aside,
  },
  data() {
    return {
      isMenuClose: false,
    };
  },
  methods: {
    menuToggle(value) {
      this.isMenuClose = value;
    },
  },
  created() {
    const userGroups = this.$store.state.auth.user.groups;
    // [1,2,3,4,5].some(n => userGroups.includes(n)) ? this.$router.push({name:'UserList'}) : this.$router.push({name:'Apps'})
  },
  computed: {
    ...mapGetters("theme", {
      breadcreamps: "getBreadcrumps",
    }),
  },
};
</script>